














import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import SharedMixin from '@/mixins/SharedMixin';

import { VendorModel } from '@/services/iResourceService';

@Component({})
export default class PgaVendorsTable extends mixins(SharedMixin)
{
    @Prop({ default: function() { return new Array<VendorModel>() } }) readonly VendorsList!: Array<VendorModel>;
    @Prop({ default: true }) readonly isLoading!: boolean;

    headers: Array<any> =
    [
		{
			text: 'Code',
			align: 'start',
			sortable: true,
            value: 'Code',
            width: "10%",
            keySearch: "co",
        },
        {
			text: 'Business Name',
			align: 'start',
			sortable: true,
			value: 'BusinessName',
            keySearch: "bn",
        },
        {
            text: 'Status',
            allign: 'start',
            value: 'Status',
            width: '10%'
        }
    ];

    rowClicked (item: any) : void
    {
		const id = item.Id;
		this.$router.push({ name: 'vendordetails', params: { id }});
	}
}
