














import { Component } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import SharedMixin from "@/mixins/SharedMixin";

import { VendorStore, CreateVendorModel } from './store';
import PgaVendorCreate from './Components/PgaVendorCreate.vue';
import { CreateVendorResponse } from '@/services/iResourceService';

@Component({
	components:
	{
		PgaVendorCreate
	}
})
export default class PgaVendorPageCreate extends mixins(SharedMixin)
{
	get VendorCreate() : CreateVendorModel 
	{
		return VendorStore.VendorCreate;
	}

	mounted() : void
	{
		VendorStore.InitVendorCreate();
	}

    async Create() : Promise<void>
    {
        try 
        {
			const response: CreateVendorResponse = await VendorStore.CreateVendor();
			const id: string = response.Id.toString();
			this.$router.replace({ name: 'vendordetails', params: { id }});
		}
        // eslint-disable-next-line no-empty
        catch(error) { }
	}

    undo() : void 
    {
		this.$router.go(-1);
	}

	beforeDestroy() : void
	{
		VendorStore.DropVendorCreate();
		VendorStore.VendorCreate.Errors = new Map<string, Array<string>>()
	}
}
