




















































import { Component } from "vue-property-decorator";
import { Guid } from 'guid-typescript';
import { mixins } from "vue-class-component";
import SharedMixin from "@/mixins/SharedMixin";

import { VendorStore } from './store';
import { EntityResourceStatus, VendorModel } from '@/services/iResourceService';
import { SharedModule } from "@/feature/Shared/store";

import PgaVendorDetails from './Components/PgaVendorDetails.vue';

@Component( {
	components:
	{
		PgaVendorDetails
	}
} )
export default class PgaVendorPageDetails extends mixins( SharedMixin )
{
	private showDialog = false;
	private title = "";
	private message = "";

	get VendorDetails (): VendorModel 
	{
		return VendorStore.VendorDetails;
	}

	async mounted (): Promise<void>
	{
		VendorStore.DropVendorDetails();
		const id: string = this.$route.params.id;
		await VendorStore.GetVendorDetails( Guid.parse( id ) );
	}

	get isRequestableDeletion (): boolean
	{
		return this.VendorDetails.Status !== EntityResourceStatus.DeletionRequest &&
			this.VendorDetails.Status !== EntityResourceStatus.Created;
	}

	get isApprovable (): boolean 
	{
		return this.VendorDetails?.Status == EntityResourceStatus.Created;
	}

	get isApprovableDeletion (): boolean 
	{
		return this.VendorDetails?.Status == EntityResourceStatus.DeletionRequest;
	}

	private ShowDialogDelete (): void
	{
		this.title = "Deleting Solution Product";
		this.message = `Are you sure you want to delete the Solution Product '${this.VendorDetails.BusinessName}'?`;
		this.showDialog = true;
	}

	private ShowDialogRequestDelete (): void
	{
		this.title = "Request Deletion Solution Product";
		this.message = `Are you sure you want to requeste deletion of Solution Product '${this.VendorDetails.BusinessName}'?`;
		this.showDialog = true;
	}

	private HideDialog (): void
	{
		this.showDialog = false;
		this.title = "";
		this.message = "";
	}

	private async Delete (): Promise<void>
	{
		try
		{
			if ( this.VendorDetails )
				await VendorStore.DeleteVendor( this.VendorDetails.Id );
			this.$router.push( { path: '/vendors' } );
		}
		finally
		{
			this.showDialog = false;
			this.title = "";
			this.message = "";
		}
	}

	private async approve (): Promise<void> 
	{
		try
		{
			await VendorStore.ApproveVendor();
		} catch ( reason )
		{
			SharedModule.SET_ALERT_ERROR_MESSAGE( `error approving Vendor: ${reason}` );
			SharedModule.SET_ALERT_IS_VISIBLE( true );
		}
	}

	private async approveDeletion (): Promise<void> 
	{
		try
		{
			await VendorStore.ApproveDeletionVendor();
			this.$router.go( -1 );
		} catch ( reason )
		{
			SharedModule.SET_ALERT_ERROR_MESSAGE( `error approving deletion Vendor: ${reason}` );
			SharedModule.SET_ALERT_IS_VISIBLE( true );
		}
	}
}
