
































import { Component, Model } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import SharedMixin from '@/mixins/SharedMixin';

import { EntityResourceStatus, VendorModel } from "@/services/iResourceService";

@Component({ })
export default class PgaVendorDetails extends mixins(SharedMixin)
{
	@Model("", { type: Object }) readonly Model!: VendorModel;

	get code(): string
	{
		return this.Model?.Code ?? 0;
	}
	get businessName(): string
	{
		return this.Model?.BusinessName ?? "";
	}
	get status(): EntityResourceStatus
	{
		return this.Model?.Status ?? EntityResourceStatus.Created;
	}
}
