






















import { Component, Model } from "vue-property-decorator";

import { mixins } from "vue-class-component";
import SharedMixin from '@/mixins/SharedMixin';

import { VendorStore } from "../store";
import { CreateVendorCommand } from "@/services/iResourceService";

@Component( {} )
export default class PgaVendorCreate extends mixins( SharedMixin )
{
	@Model( "", { type: Object } ) readonly Model!: CreateVendorCommand;

	get businessName (): string
	{
		return this.Model?.BusinessName ?? "";
	}
	set businessName ( businessName: string )
	{
		VendorStore.SET_VENDOR_CREATE_BUSINESSNAME( businessName );
	}

	get Errors (): Map<string, Array<string>>
	{
		return VendorStore.VendorCreate.Errors;
	}
}
