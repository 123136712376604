














import { Component } from "vue-property-decorator";
import { VendorStore } from './store';

import { mixins } from "vue-class-component";
import SharedMixin from "@/mixins/SharedMixin";

import PgaVendorsTable from './Components/PgaVendorsTable.vue';
import { VendorModel } from '@/services/iResourceService';

@Component({
    components:
    {
        PgaVendorsTable
    }
})
export default class PgaVendorPageIndex extends mixins(SharedMixin)
{
    private isLoading = true;

    get VendorsList() : Array<VendorModel>
    {
        return VendorStore.VendorsTable.Vendors;
    }
    
    async mounted() : Promise<void>
    {
        this.isLoading = true;
        await VendorStore.GetAllVendors();
        this.isLoading = false;        
    }
}
