import { VuexModule, Module, Mutation, getModule, Action } from 'vuex-module-decorators'
import store from '@/infrastructure/store'
import { Guid } from 'guid-typescript';

import pgaDiContainer from '@/App.container';
import IResourceService, { EntityResourceStatus } from '@/services/iResourceService';
import
{
    VendorModel, ListVendors,
    CreateVendorCommand,
    CreateVendorResponse, DeleteVendorResponse
} from '@/services/iResourceService';

import ResourceService from '@/services/ResourceService';
import { SharedModule } from '@/feature/Shared/store';

export interface VendorState
{
    VendorsTable: ListVendors;
    VendorDetails: VendorModel | null;
    VendorCreate: CreateVendorModel;
    VendorDelete: DeleteVendorModel;
}

export interface CreateVendorModel
{
    Command: CreateVendorCommand | null;
    Response: CreateVendorResponse | null;
    Errors: Map<string, Array<string>>;
}

export interface DeleteVendorModel
{
    Id: Guid;
    Response: DeleteVendorResponse | null;
}

@Module( { namespaced: true, dynamic: true, store, name: 'VendorModule', } )
class VendorsStore extends VuexModule implements VendorState
{
    _resourceService: IResourceService = pgaDiContainer.get<IResourceService>( ResourceService );

    public VendorsTable: ListVendors =
        {
            Vendors: new Array<VendorModel>(),
        };

    public VendorDetails: VendorModel =
        {
            Id: Guid.createEmpty(),
            Code: '',
            BusinessName: '',
            Status: EntityResourceStatus.Created,
        };

    public VendorCreate: CreateVendorModel =
        {
            Command: null,
            Response: null,
            Errors: new Map<string, Array<string>>()
        };

    public VendorDelete: DeleteVendorModel =
        {
            Id: Guid.createEmpty(),
            Response: null,
        }

    // List Vendors
    @Action( { rawError: true } )
    public async GetAllVendors ()
    {
        const vendors = await this._resourceService.GetAllVendors();
        this.SET_VENDORS( vendors );
    }
    @Mutation
    SET_VENDORS ( vendors: ListVendors )
    {
        this.VendorsTable = vendors;
    }

    // Details Vendor
    @Action( { rawError: true } )
    public async GetVendorDetails ( id: Guid )
    {
        SharedModule.SET_ISLOADING( true );
        const vendor: VendorModel = await this._resourceService.GetVendor( id );
        this.SET_VENDOR_DETAILS( vendor );
        SharedModule.SET_ISLOADING( false );
    }
    @Action( { rawError: true } )
    public DropVendorDetails ()
    {
        const resetVendorDetails: VendorModel =
        {
            Id: Guid.createEmpty(),
            Code: '',
            BusinessName: '',
            Status: EntityResourceStatus.Created,
        };
        this.SET_VENDOR_DETAILS( resetVendorDetails );
    }
    @Mutation
    public SET_VENDOR_DETAILS ( vendor: VendorModel )
    {
        this.VendorDetails = vendor;
    }
    @Mutation
    public SET_VENDOR_DETAILS_ID ( id: Guid )
    {
        this.VendorDetails.Id = id;
    }
    @Mutation
    public SET_VENDOR_DETAILS_CODE ( code: string )
    {
        this.VendorDetails.Code = code;
    }
    @Mutation
    public SET_VENDOR_DETAILS_BUSINESSNAME ( businessName: string )
    {
        this.VendorDetails.BusinessName = businessName;
    }
    @Mutation
    public SET_VENDOR_DETAILS_STATUS ( status: EntityResourceStatus )
    {
        this.VendorDetails.Status = status;
    }

    // Create Vendor
    @Action( { rawError: true } )
    public async CreateVendor (): Promise<CreateVendorResponse>
    {
        try
        {
            let response: CreateVendorResponse = { Id: Guid.createEmpty() };
            SharedModule.SET_ISLOADING( true );

            if ( this.VendorCreate.Command )
                response = await this._resourceService.CreateVendor( this.VendorCreate.Command );

            SharedModule.SET_ISLOADING( false );
            return response;
        }
        catch ( error )
        {
            SharedModule.SET_ISLOADING( false );
            const errs = parseErrors( error );
            this.SET_VENDOR_CREATE_ERRORS( errs );

            throw error;
        }
    }
    @Action( { rawError: true } )
    public InitVendorCreate ()
    {
        const command: CreateVendorCommand =
        {
            BusinessName: '',
        }

        this.SET_VENDOR_CREATE( command );
    }
    @Action( { rawError: true } )
    public async DropVendorCreate ()
    {
        this.SET_VENDOR_CREATE( null );
    }
    @Mutation
    public SET_VENDOR_CREATE ( command: CreateVendorCommand | null )
    {
        this.VendorCreate.Command = command;
    }
    @Mutation
    public SET_VENDOR_CREATE_BUSINESSNAME ( businessName: string )
    {
        if ( this.VendorCreate.Command )
            this.VendorCreate.Command.BusinessName = businessName;
    }
    @Mutation
    public SET_VENDOR_CREATE_ERRORS ( errs: Map<string, Array<string>> )
    {
        this.VendorCreate.Errors = errs;
    }

    // Delete Vendor
    @Action( { rawError: true } )
    public async DeleteVendor ( id: Guid ): Promise<DeleteVendorResponse>
    {
        try
        {
            let response: DeleteVendorResponse = { Id: Guid.createEmpty() };
            SharedModule.SET_ISLOADING( true );
            this.SET_VENDOR_DELETE( id );

            if ( this.VendorDelete )
                response = await this._resourceService.DeleteVendor( this.VendorDelete.Id );
            SharedModule.SET_ISLOADING( false );
            return response
        }
        catch ( error )
        {
            SharedModule.SET_ISLOADING( false );

            const errs = parseErrors( error );
            const message = errs.get( 'id' )?.map( e => e = `- ${e}` ).join( " <br> " ) ?? "";
            SharedModule.SET_ALERT_ERROR_MESSAGE( message );
            SharedModule.SET_ALERT_IS_VISIBLE( true );

            throw error;
        }
    }
    @Mutation
    public SET_VENDOR_DELETE ( id: Guid )
    {
        this.VendorDelete.Id = id;
    }

    // APPROVE
    @Action({ rawError: true })
    public async ApproveVendor(): Promise<void> {
        SharedModule.SET_ISLOADING(true);
        try {
            if (this.VendorDetails == null)
                return Promise.reject("Details Vendor not initialized, try reload the application");

            const vnd = this.VendorDetails;
            const cmd = { Id: vnd.Id };
            await this._resourceService.ApproveVendor(cmd);

            this.SET_VENDOR_DETAILS_STATUS(EntityResourceStatus.Approved);
        } finally {
            SharedModule.SET_ISLOADING(false);
        }
    }

    // APPROVE DELETION
    @Action({ rawError: true })
    public async ApproveDeletionVendor(): Promise<void> {
        SharedModule.SET_ISLOADING(true);
        try {
            if (this.VendorDetails == null)
                return Promise.reject("Details Vendor not initialized, try reload the application");

            const vnd = this.VendorDetails;
            const cmd = { Id: vnd.Id };
            await this._resourceService.ApproveDeletionVendor(cmd);

            this.SET_VENDOR_DETAILS_STATUS(EntityResourceStatus.Deleted);
        } finally {
            SharedModule.SET_ISLOADING(false);
        }
    }
}

function parseErrors ( error: any ): Map<string, Array<string>>
{
    const errs = new Map<string, Array<string>>();
    for ( const [key, value] of Object.entries( error.response.data ) )
    {
        const messages = value as Array<string>;
        errs.set( key, messages );
    }
    return errs;
}

export const VendorStore = getModule( VendorsStore );